* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
  }
  
  body {
    margin: 0;
    background: url('../../public/assets/video60revtransparancy.gif') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
  }
  
  footer {
    background: #fff;
    background: rgba(255,255,255,0.9);
  }
  
  footer .copyright {
    margin: 0;
    padding: 20px 0;
  }
  
  hr {
    height: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
    border-color: #999999;
    max-width: 400px;
  }
  
  h1 {
    margin: .67em 0;
    font-size: 2em;
  }
  
  h2 {
    font-size: 30px;
  }
  
  h1,
  h2 {
    font-family: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    line-height: 1.1;
  }
  
  h1 small,
  h2 small {
    font-size: 65%;
    font-weight: normal;
    line-height: 1;
    color: #777;
  }
  
  .text-center {
    text-align: center;
  }
  
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
  
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .container:before,
  .container:after,
  .row:before,
  .row:after {
    display: table;
    content: " ";
  }
  
  .container:after,
  .row:after {
    clear: both;
  }
  
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 1200px) {
    .col-lg-12 {
      float: left;
      width: 100%;
    }
  }
  
  
  
  
  /* Page Specific Styling */
  
  .brand {
    display: none;
  }
  
  @media screen and (min-width:768px) {
    .brand {
        display: inherit;
        margin: 0;
        padding: 30px 0 10px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        font-family: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 5em;
        font-weight: 700;
        line-height: normal;
        color: #fff;
    }
  }
  
  .brand-before {
    margin: 15px 0;
  }
  
  .brand-name {
    margin: 0;
    font-size: 4em;
  }
  
  .tagline-divider {
    margin: 15px auto 3px;
    max-width: 250px;
    border-color: #999999;
  }
  
  .box {
    margin-bottom: 20px;
    padding: 30px 15px;
    background: #fff;
    background: rgba(255,255,255,0.9);
  }
  
  .intro-text {
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  
  @media screen and (min-width:1200px) {
    .box:after {
        content: '';
        display: table;
        clear: both;
    }
  }